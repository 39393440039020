import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectWithGift } from '../../store/orderSlice';
import { fetchEndUserInfo } from '../../store/userSlice';
import { Button, Table, Row, Col } from 'antd';
import { usePDF } from "react-to-pdf";
import GiftCertificate from '../../certificateComp/GiftCertificate';

function ProjectOrderWithGiftDataTable() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const ProjectGiftOrder = useSelector((state) => state.orderReducer.projectOrderWithGift);

    const { toPDF, targetRef } = usePDF();
    const [certificateData, setCertificateData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (userInfo?._id) {
            dispatch(fetchProjectWithGift(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    useEffect(() => {
        if (!userInfo._id) {
            dispatch(fetchEndUserInfo(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    const handleDownloadCertificate = (order) => {
        setCertificateData(order);
        setTimeout(() => {
            toPDF({
                filename: `${order?.projectName}-${order?.purchasedDate}-certificate.pdf`,
                page: {
                    format: 'letter',
                    orientation: 'landscape',
                },
            });
        }, 0);
    };

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        width={50}
                        src={record?.img}  // Assuming you have the project image URL
                        alt={text}
                        style={{ marginRight: 8 }}
                    />
                    {text}
                </div>
            ),
        },
        {
            title: 'Gifted By',
            dataIndex: 'giftBy',
            key: 'giftBy',
        },
        {
            title: 'Receiver Email',
            dataIndex: 'receiverEmail',
            key: 'receiverEmail',
        },
        {
            title: 'Occasion',
            dataIndex: 'occasion',
            key: 'occasion',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Purchased Date',
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (date) => new Date(date).toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button type="default" onClick={() => handleDownloadCertificate(record)}>
                    Download Certificate
                </Button>
            ),
        },
    ];

    const dataSource = ProjectGiftOrder.map((order, index) => ({
        key: index,
        projectName: order?.plants[0]?.projectId?.name,
        giftBy: order?.userId?.name,
        receiverEmail: order?.plants[0]?.giftId?.receiverEmail,
        occasion: order?.plants[0]?.giftId?.Occasion,
        mobileNumber: order?.plants[0]?.giftId?.mobileNumber,
        quantity: order?.plants[0]?.quantity,
        img: process.env.REACT_APP_BACKEND_URL + "/" + order?.plants[0]?.projectId?.images[0],
        total: order?.total,
        purchasedDate: order.purchasedDate,
    }));

    const noDataMessage = <div style={{ color: 'white', textAlign: 'center', padding: '20px' }}>No data available</div>;

    return (
        <>
            {isMobile ? (
                dataSource.length === 0 ? noDataMessage : (
                    <Row gutter={16}>
                        {dataSource.map((order) => (
                            <Col span={24} key={order.key}>
                                <div style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px', fontWeight: '700' }}>
                                    <p>Project Name:</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            width={50}
                                            src={order.img}
                                            alt={order.projectName}
                                            style={{ marginRight: 8 }}
                                        />
                                        <h3 style={{ margin: 0 }}>{order.projectName}</h3>
                                    </div>
                                    <p>Gifted By: {order.giftBy}</p>
                                    <p>Receiver Email: {order.receiverEmail}</p>
                                    <p>Occasion: {order.occasion}</p>
                                    <p>Mobile No: {order.mobileNumber}</p>
                                    <p>Quantity: {order.quantity}</p>
                                    <p>Total Amount: {order.total}</p>
                                    <p>Purchased Date: {new Date(order.purchasedDate).toLocaleString()}</p>
                                    <Button type="default" onClick={() => handleDownloadCertificate(order)}>
                                        Download Certificate
                                    </Button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )
            ) : (
                dataSource.length === 0 ? noDataMessage : (
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '30', '40'],
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                )
            )}

            {certificateData && (
                <GiftCertificate ref={targetRef} user={userInfo} data={certificateData} />
            )}
        </>
    );
}

export default ProjectOrderWithGiftDataTable;
