import React from 'react'
import { Carousel } from 'antd';
import styles from './styles.module.css'
// import 'antd/dist/antd.css';

function ClimateCrisis() {
    const climateData = [
        {
            title: 'The Urban Pollution Crisis',
            description: `Urban pollution is choking our cities, affecting air quality, and posing health risks. Smog-filled skies and
            dying greenery need urgent action. Planting trees in urban areas can filter air, reduce noise pollution,
            and create healthier environments. Every individual can make a difference by planting a tree.`,
            images: [
                '/assets/images/crisis/smog.jpg',
                '/assets/images/crisis/view-trees.jpg',
                // '/assets/images/crisis/air-quality-infographic.jpg'
            ],
            background: '/assets/images/crisis/smog.jpg'
        },
        {
            title: 'Your Role in Reforestation',
            description: `Every tree you plant contributes to a cleaner, healthier city. By planting trees, you can help reduce urban
            heat, improve air quality, and support local biodiversity. Take action today to combat pollution and build a
            sustainable urban environment for future generations.`,
            images: [
                '/assets/images/crisis/group-tree.jpg',
                '/assets/images/crisis/view-trees.jpg',
                '/assets/images/crisis/family-treePlant.jpg',

            ],
            background: '/assets/images/crisis/smog.jpg' // Add background image for each slide

        }
    ];


    return (
        <>
            {/* <Carousel dots={true} arrows>
            {climateData.map((item, index) => (
                <section
                    key={index}
                    className={styles.climateCrisisSection}
                    style={{
                        backgroundImage: `url(${item.background})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100vh', // Adjust height to cover the viewport
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className={`container ${styles.contentContainer}`}>
                        <div className="row align-items-center">
                            <div className={`col-lg-6 col-md-12 textContent ${styles.textStyle}`}>
                                <h2 className={styles.crisistitleStyle}>{item.title}</h2>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </Carousel> */}
            <Carousel dots={false} arrows >
                {climateData.map((item, index) => (

                    <section
                        key={index}
                        className={`ptb-120 climate-crisis ${index === 1 ? 'sliderTwoImg' :  'sliderOneImg'}`}
                        style={{
                            backgroundImage: `url(${item.background})`,
                        }}
                        >
                        <div className={`container ${styles.containerFluidCrisis}`}>
                            {/* <h1 className={styles.title}>Climate Crisis</h1> */}
                            <div className={`carousel-slide ${styles.carouselSlide}`}>
                                <div className={`container ${styles.contentContainer}`}>
                                    <div className="row align-items-center">
                                        <div className={`col-lg-6 col-md-12 textContent ${styles.textStyle}`}>
                                            <h2 className={styles.crisistitleStyle} style={{color:'white'}}>{item.title}</h2>
                                            <p style={{color:'white'}}>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={`shape ${styles.treeLeaf}`}>
                    <img src='/assets/images/price-shape.png' alt='shape' />
                    </div> */}
                        </div>
                    </section>
                ))}
            </Carousel>
        </>

    )
}

export default ClimateCrisis