import React, { useEffect, useRef, useState } from 'react'
import BrandItem from './BrandItem';
import { HashLink as Link } from 'react-router-hash-link';
import { Carousel } from 'antd';
import 'antd/dist/reset.css';
import { useInView } from 'react-intersection-observer';
import 'animate.css';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.css'; 
// import { Autoplay } from 'swiper'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import styles from './styles.module.css'

function Brand({ id }) {
    const brandLogos = [
        { imgSrc: '/assets/images/brand/brand-1.jpeg', altText: 'Brand 1' },
        { imgSrc: '/assets/images/brand/brand-2.jpeg', altText: 'Brand 2' },
        { imgSrc: '/assets/images/brand/brand-3.jpeg', altText: 'Brand 3' },
        { imgSrc: '/assets/images/brand/brand-4.jpeg', altText: 'Brand 4' },
        { imgSrc: '/assets/images/brand/brand-5.jpeg', altText: 'Brand 5' },
        { imgSrc: '/assets/images/brand/brand-6.jpeg', altText: 'Brand 6' },
        { imgSrc: '/assets/images/brand/brand-7.jpeg', altText: 'Brand 7' },
    ];

    const [infiniteLogos, setInfiniteLogos] = useState([]);

    useEffect(() => {
        // Duplicate logos multiple times for seamless scrolling
        const duplicates = Array(5)?.fill(brandLogos)?.flat();
        setInfiniteLogos(duplicates);
    }, []);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <>
            {/* <!--Start brand-section--> */}
            <section className="ch-cta-area" id={id}>
                <div className="container">
                    <div className="brand-section style-2">
                        <div className="container">

                            <div className="row justify-content-center">
                                <div className="col col-lg-5 col-md-12 col-12">
                                    <div className="ch-top-title-s2">

                                        <h2 className="site-split-text ch-split-in-up">Our Clients</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    {/* <Swiper
                                        className={styles.brandSlider}
                                        modules={[Autoplay]}
                                        spaceBetween={30}
                                        autoplay={{
                                            delay: 0, // No delay between transitions
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: false,
                                        }}
                                        speed={8000} // Speed of the scroll
                                        loop={true} // Enable looping
                                        slidesPerView={6} // Number of visible slides
                                        allowTouchMove={false}
                                        breakpoints={{
                                            1024: { slidesPerView: 5 },
                                            768: { slidesPerView: 4 },
                                            480: { slidesPerView: 2 },
                                        }}
                                    >
                                        {infiniteLogos.map((brand, index) => (
                                            <SwiperSlide key={index}>
                                                <BrandItem imgSrc={brand.imgSrc} altText={brand.altText} />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper> */}

                                    <marquee behavior="scroll" direction="left" scrollamount="5" loop="infinite">
                                        {infiniteLogos.map((brand, index) => (
                                            <BrandItem key={index} imgSrc={brand.imgSrc} altText={brand.altText} />
                                        ))}
                                    </marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End brand-section--> */}

                    {/* <!-- start ch-cta-area --> */}

                    <div ref={ref} className="cta-items">
                        <div className={`cta-text ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                            <h2>Contributions that last a generation</h2>
                            <h3>Plant A Tree Today!</h3>
                        </div>
                        <div className={`cta-img ${inView ? 'animate__animated animate__fadeInUp' : ''}`} style={{ animationDuration: '1600ms' }}>
                            <img src="/assets/images/animal-treetology.png" alt="" />
                        </div>
                        <div className={`cta-btn ${inView ? 'animate__animated animate__fadeInUp' : ''}`} style={{ animationDuration: '1600ms' }}>
                            <Link to="/#our-projects" className="ch-btn-style-3">Plant A Tree</Link>
                        </div>
                        <div className={`shape ${inView ? 'animate__animated animate__fadeInLeft' : ''}`} style={{ animationDuration: '1600ms' }}>
                            <img src="assets/images/cta/leaf.png" alt="" />
                        </div>
                        <div className={`shape-2 ${inView ? 'animate__animated animate__fadeInRight' : ''}`} style={{ animationDuration: '1600ms' }}>
                            <img src="/assets/images/cta/plane.png" alt="" />
                        </div>
                    </div>
                </div>
                {/* <!-- end container --> */}
            </section>
            {/* <!-- end ch-cta-area --> */}
        </>
    )
}

export default Brand