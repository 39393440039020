import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import SpaIcon from '@mui/icons-material/Spa';
import { useNavigate, useParams } from 'react-router-dom';
import ForestIcon from '@mui/icons-material/Forest';
import { Carousel } from 'antd';
import { fetchEndUserInfo } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchwebsiteProjectsByLink, setCheckoutData } from '../../store/projectSlice';
import axios from 'axios';
// import CurrencySelector from '../../store/CurrencySelector';
import useCurrencyConverter from '../../store/useCurrencyConverter';
import CurrencySelector from '../../store/CurrencySelector';
import { setSelectedCurrency } from '../../store/currencySlice';
import { Helmet } from 'react-helmet';


function ProjectDetailPage() {
  const link = useParams();
  const dispatch = useDispatch();
  const selectedProject = useSelector((state) => state.projectReducer.selectedProject)
  const navigate = useNavigate()
  const userInfo = useSelector((state) => state.userReducer.userInfo)
  const [selectedType, setSelectedType] = useState(() => { "plant" });
  const [Count, setCount] = useState(1);

  const selectedCurrency = useSelector((state) => state.currencyReducer.selectedCurrency);
  // const { convertedPlantPrice, convertedAdoptPrice } = useCurrencyConverter(selectedCurrency, selectedProject);
  const { exchangeRate } = useCurrencyConverter(selectedCurrency);

  const [convertedPlantPrice, setConvertedPlantPrice] = useState(0);
  const [convertedAdoptPrice, setConvertedAdoptPrice] = useState(0);

  // Log converted prices for debugging
  console.log(`Converted Plant Price: ${convertedPlantPrice} ${selectedCurrency}`);
  console.log(`Converted Adopt Price: ${convertedAdoptPrice} ${selectedCurrency}`);

  useEffect(() => {
    dispatch(fetchwebsiteProjectsByLink(link.id))
  }, [dispatch, link.id])

  useEffect(() => {
    dispatch(fetchEndUserInfo())
  }, [dispatch])

  const handleBtnClick = async (option) => {
    if (!userInfo && !userInfo?._id && Object?.keys(userInfo)?.length === 0) {
      navigate('/login');
    } else {
      setSelectedType(option)
      dispatch(setCheckoutData({
        qty: Count,
        plantPrice: option === 'plant' ? selectedProject?.plantPrice : 0,
        adoptPrice: option === 'adopt' ? selectedProject?.adoptPrice : 0,
        selectedOption: option,
        name: selectedProject?.name
      }));

      navigate(`/view-cart/${selectedProject?.link}`);
    }
  };

  const incrementCount = () => {
    // if (plantCount > 1) {
    setCount(prevCount => prevCount + 1);
    // }
  };

  const decrementCount = () => {
    if (Count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (selectedProject && exchangeRate) {
      // Convert prices from INR to the selected currency
      const plantPriceInINR = selectedProject.plantPrice; // Assuming the price is in INR
      const adoptPriceInINR = selectedProject.adoptPrice; // Assuming the price is in INR

      setConvertedPlantPrice((plantPriceInINR * exchangeRate).toFixed(2));
      setConvertedAdoptPrice((adoptPriceInINR * exchangeRate).toFixed(2));
    }
  }, [selectedProject, exchangeRate]);


  // useEffect(() => {
  //   if (selectedProject) {
  //     // Define the JSON-LD schema
  //     const breadcrumbSchema = {
  //       "@context": "https://schema.org/",
  //       "@type": "BreadcrumbList",
  //       "itemListElement": [
  //         {
  //           "@type": "ListItem",
  //           "position": 1,
  //           "name": "Treetology",
  //           "item": "https://treetology.com/"
  //         },
  //         {
  //           "@type": "ListItem",
  //           "position": 2,
  //           "name": "Our Projects",
  //           "item": "https://treetology.com/#our-projects"
  //         },
  //         {
  //           "@type": "ListItem",
  //           "position": 3,
  //           "name": selectedProject.name,
  //           "item": `https://treetology.com/ProjectDetails/${selectedProject.link}`
  //         }
  //       ]
  //     };

  //     // Create a script element
  //     const script = document.createElement('script');
  //     script.type = 'application/ld+json';
  //     script.text = JSON.stringify(breadcrumbSchema);

  //     // Append the script to the head
  //     document.head.appendChild(script);

  //     // Clean up by removing the script when the component unmounts or selectedProject changes
  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }
  // }, [selectedProject]);

  return (
    <>

      {/* {breadcrumbSchema && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbSchema)}
          </script>
        </Helmet>
      )} */}

      <div className={`container ptb-120 ${styles.cartContainer}`}>
        <div className='row'>
          {/* Main Image and Thumbnails */}
          <div className='col-lg-6 col-md-6 mx-auto'>
            <Carousel autoplay dots={false} arrows {...carouselSettings}>
              {selectedProject?.images?.map((imgSrc, index) => (
                <div key={index} className={styles.mainImage}>
                  <img
                    src={process.env.REACT_APP_BACKEND_URL + "/" + imgSrc}
                    alt={`Project ${index + 1}`}
                    className="img-fluid"
                  />
                </div>
              ))}
            </Carousel>


            <div className="mt-4">
              <div className={`d-flex align-items-center justify-content-center ${styles.counterBg}`}>
                <span className={styles.StripText} >No. of trees Purchase:</span>
                <button className="btn btn-outline-secondary" style={{ color: 'white', borderColor: 'white' }} onClick={decrementCount}>-</button>
                <span className="mx-3" style={{ color: 'white' }}>{Count}</span>
                <button className="btn btn-outline-secondary" style={{ color: 'white', borderColor: 'white' }} onClick={incrementCount}>+</button>
              </div>
            </div>
          </div>

          {/* Text and Buttons */}
          <div className='col-lg-6 col-md-6'>
            <div className={styles.contentSection}>
              <h1 style={{ fontWeight: '800' }}>{selectedProject?.name}</h1>
              <p>
                {selectedProject?.description}
              </p>

              {/* <CurrencySelector currency={currency} handleCurrencyChange={handleCurrencyChange} /> */}

              {/* <CurrencySelector /> */}
              <CurrencySelector />



              <ul className={styles.iconList}>
                {selectedProject?.carbonSequestration && (
                  <li><SpaIcon className={styles.icon} /><span className={styles.pointPara}>Targeted Carbon Sequestration of the project : {selectedProject?.carbonSequestration}</span></li>
                )}

                {selectedProject?.country && (
                  <li><SpaIcon className={styles.icon} /><span className={styles.pointPara}>Country : {selectedProject?.country}</span></li>
                )}

                {selectedProject?.location && (
                  <li><SpaIcon className={styles.icon} /><span className={styles.pointPara}>Location : {selectedProject?.location}</span></li>
                )}

                {selectedProject?.area && (
                  <li><SpaIcon className={styles.icon} /><span className={styles.pointPara}>Area : {selectedProject?.area}</span></li>
                )}

              </ul>

              <div className="col-md-6 text-center plantationBrdr">
                <div className="" data-aos="fade-left" data-aos-once="true">
                  <div className="task-progress">
                    <h3><ForestIcon className='popup-icon flip-icon' style={{ marginRight: '8px' }} />Plantation target &nbsp;
                      {/* <span>{achieved}/{target}</span> */}
                      <span>{selectedProject?.plantationTarget}/{selectedProject?.plantationTargetBy}</span>

                    </h3>
                    <div className="progress" style={{ width: '100%', backgroundColor: '#f0f0f0', borderRadius: '10px', height: '1rem' }}>
                      <div
                        // ref={progressBarRef}
                        className="progress-bar"
                        style={{
                          // width: 200,
                          width: `${(selectedProject?.plantationTarget / selectedProject?.plantationTargetBy) * 100}%`,
                          backgroundColor: '#009a00',
                          height: '100%',
                          borderRadius: '10px',
                          transition: 'width 2s ease-in-out'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gap-3 mt-4">
                <div className={`col-lg-4 col-md-4 mb-2 mt-2 text-center ${styles.plantNowBox}`}>
                  {/* <h2 style={{ fontSize: '24px' }}>Price: ₹ {selectedProject?.plantPrice}</h2> */}

                  <h2 style={{ fontSize: '24px' }}>
                    Price: {selectedCurrency} {convertedPlantPrice}
                  </h2>

                  <button
                    className={`ch-btn-style-1 ${selectedType === 'plant' ? 'active' : ''}`}
                    // onClick={handleBtnClick}
                    onClick={() => handleBtnClick('plant')}
                  >
                    Plant Now
                  </button>
                </div>

                <div className={`col-lg-4 col-md-4 mb-2 mt-2 text-center ${styles.plantNowBox}`}>
                  {/* <h2 style={{ fontSize: '24px' }}>Price: ₹ {selectedProject?.adoptPrice}</h2> */}
                  <h2 style={{ fontSize: '24px' }}>
                    Price: {selectedCurrency} {convertedAdoptPrice}
                  </h2>

                  <button
                    className={`ch-btn-style-1 ${selectedType === 'adopt' ? 'active' : ''}`}
                    onClick={() => handleBtnClick('adopt')}
                  >
                    Adopt Tree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDetailPage;
