import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { saveReducerInfo } from '../store/userSlice';
import Localstorage from '../utils/storage/Localstorage';
import Notification from '../component/notification/Notification';

const OauthCallback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
console.log("OauthCallback **********")
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        console.log("OAuthCallbackparams", params)
        const token = params.get('token');
        const userData = params.get('user'); // Ensure this matches the backend
        console.log("OAuthCallback", userData)
        if (token && userData) {
            Localstorage.JWT_TOKEN.set(token);
            dispatch(saveReducerInfo(JSON.parse(userData)));

            // if (redirectionSelector === '/checkout') {
            //     router.back();
            //     dispatch(removeRedirectionPath());
            // } else {
            //     // router.push('/student-dashboards/student-dashboard');
            //     router.push('/student-dashboards/student-profile');
            // }
            navigate('/');
        } else {
            Notification({ message: 'Authentication failed.', type: 'error' });
        }
    }, [navigate, dispatch]);

    return <div>Loading...</div>;
};

export default OauthCallback;
