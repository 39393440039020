import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'
import projectReducer from './projectSlice'
import orderReducer from './orderSlice'
// import cartReducer from './cartSlice'
import currencyReducer from './currencySlice'
import blogsReducer from './blogsSlice'


export const store = configureStore({
  reducer: {
    userReducer: userReducer,
    projectReducer: projectReducer,
    orderReducer: orderReducer,
    // cartReducer:cartReducer
    currencyReducer: currencyReducer,
    blogsReducer: blogsReducer,
  },
});

export default store;
