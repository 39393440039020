import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectWithoutGift } from '../../store/orderSlice';
import { fetchEndUserInfo } from '../../store/userSlice';
import { Button, Table, Row, Col } from 'antd';
import { usePDF } from "react-to-pdf";
import Certificate from '../../certificateComp/Certificate';

function ProjectOrderDataTable() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const ProjectOrder = useSelector((state) => state.orderReducer.projectOrderWithoutGift);
    const { toPDF, targetRef } = usePDF();
    const [certificateData, setCertificateData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (userInfo?._id) {
            dispatch(fetchProjectWithoutGift(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    useEffect(() => {
        if (!userInfo._id) {
            dispatch(fetchEndUserInfo(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    const handleDownloadCertificate = (order) => {
        setCertificateData(order);
        setTimeout(() => {
            toPDF({
                filename: `${order?.projectName}-${order?.purchasedDate}-certificate.pdf`,
                page: {
                    format: 'letter',
                    orientation: 'landscape',
                },
            });
        }, 0);
    };

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        width={50}
                        src={record?.img}  // Assuming you have the project image URL
                        alt={text}
                        style={{ marginRight: 8 }}
                    />
                    {text}
                </div>
            ),
        },

        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Purchased Date',
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button type="default" onClick={() => handleDownloadCertificate(record)}>
                    Download Certificate
                </Button>
            ),
        },
    ];

    const dataSource = ProjectOrder.map((order, index) => ({
        key: index,
        projectName: order?.plants[0]?.projectId?.name,
        location: order?.plants[0]?.projectId?.location,
        quantity: order?.plants[0]?.quantity,
        img: process.env.REACT_APP_BACKEND_URL + "/" + order?.plants[0]?.projectId?.images[0],
        total: order?.total,
        type: order?.plants[0]?.selectType,
        purchasedDate: order.purchasedDate,
    }));

    return (
        <>
        {dataSource.length === 0 ? (
            // No data available message
            <div style={{ textAlign: 'center', color: 'white', padding: '20px', fontSize: '18px' }}>
                No Data Available
            </div>
        ) : isMobile ? (
            <Row gutter={16}>
                {dataSource.map((order) => (
                    <Col span={24} key={order.key}>
                        <div style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px', fontWeight: '700' }}>
                            <p>Project Name:</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    width={50}
                                    src={order.img}  // Project image URL
                                    alt={order.projectName}
                                    style={{ marginRight: 8 }}
                                />
                                <h3 style={{ margin: 0 }}>{order.projectName}</h3>
                            </div>
                            <p>Location: {order.location}</p>
                            <p>Quantity: {order.quantity}</p>
                            <p>Total Amount: {order.total}</p>
                            <p>Type: {order.type}</p>
                            <p>Purchased Date: {new Date(order.purchasedDate).toLocaleString()}</p>
                            <Button type="default" onClick={() => handleDownloadCertificate(order)}>
                                Download Certificate
                            </Button>
                        </div>
                    </Col>
                ))}
            </Row>
        ) : (
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                }}
                scroll={{ x: 'max-content' }} 
            />
        )}

        {certificateData && (
            <Certificate ref={targetRef} user={userInfo} data={certificateData} />
        )}
    </>
    );
}

export default ProjectOrderDataTable;
